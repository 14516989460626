import Model from './Model';
import { MeasureType, UnitType } from '../types/enums';
import { store as unitStore } from '../components/hooks/store/useUnitStore';

abstract class MeasurementModel extends Model {

    public isMetric(type: MeasureType): boolean {
        return unitStore.get(type) === UnitType.Metric;
    }

}

export default MeasurementModel;
