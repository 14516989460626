import Collection from '../Collection';
import User from './User';

class Users extends Collection<User> {

    constructor(data: any[] = []) {
        super(data, (model: any) => new User(model));
    }

}

export { User };
export default Users;
