import { get, set, isset } from '@aerisweather/javascript-sdk/dist/utils';
import Model from '../Model';
import { UserRole, StatusType } from '../../types/enums';

interface User {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    registeredAt: string;
    active: boolean;
    role: string;
    preferences: any;
    stations: any[];
}

class User extends Model {

    protected get isMutable(): boolean {
        return true;
    }

    protected get mapping(): { [key: string]: string } {
        return {
            id: 'id',
            username: 'username',
            level: 'userlevel',
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            registeredAt: 'createdDate',
            active: 'active',
            role: 'role',
            preferences: 'prefs',
            stations: 'stations',
        };
    }

    // properties that can get sent with post/put requests to the api
    protected get editableProps(): string[] {
        return [
            'firstName',
            'lastName',
            'email',
            'password',
            'prefs',
            'active',
            'role',
        ];
    }

    public get id(): string {
        return this.getValue('id');
    }

    public get name(): string {
        return `${this.firstName || ''} ${this.lastName || ''}`;
    }

    public get totalStations(): number {
        return this.stations ? this.stations.length : 0;
    }

    public get status(): StatusType {
        if (this.active === true) {
            return StatusType.Active;
        }
        return StatusType.Inactive;
    }

    public get isAdmin(): boolean {
        return this.role === UserRole.Admin;
    }

    public getPreference(key: string): any {
        const prefs = this.preferences;
        return get(prefs, key);
    }

    public setPreference(key: string, value: any) {
        const prefs = this.preferences;
        set(prefs, key, value);
        this.preferences = { ...prefs };
    }

    public static toApiObject(data: any): any {
        const result: any = {};
        const allowed = this.prototype.editableProps;

        // map data properties to their respective property the api expects
        Object.keys(data).forEach((key) => {
            const k = this.prototype.mapping[key];
            if (k && allowed.indexOf(k) !== -1) {
                result[k] = data[key];
            }
        });

        if (isset(data.password)) {
            result.password = data.password;
        }

        return result;
    }

}

export default User;
