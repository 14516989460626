import ApiRoute from '../ApiRoute';
import { ApiResult } from '../Api';
import Place from '../../models/Place';

class LocationRoute extends ApiRoute {

    public get path(): string {
        return '/place';
    }

    public async geocode(address: string): Promise<ApiResult<Place>> {
        return this.service.read<Place>(this.getPath('/geocode'), {
            address,
            decoder: (data: any): Place => new Place(data.location),
        });
    }

    public async elevation(lat: number, lon: number): Promise<ApiResult<Place>> {
        return this.service.read<Place>(this.getPath('/profile'), {
            lat,
            long: lon,
            decoder: (data: any): Place => new Place(data.location),
        });
    }

}

export default LocationRoute;
