import ApiRoute from '../ApiRoute';
import { ApiResult } from '../Api';
import Stations, { Station } from '../../models/stations/Stations';
import Observations from '../../models/stations/Observations';
import FileDownload from '../../models/FileDownload';
import StationLogs from '../../models/stations/StationLogs';

class StationsRoute extends ApiRoute {

    public get path(): string {
        return this.service.session.isAdmin ? '/manage' : '/user';
    }

    public async getStations(params?: any): Promise<ApiResult<Stations>> {
        return this.service.read<Stations>(this.getPath('/stations'), {
            ...params,
            decoder: (data: any): Stations => new Stations(data.stations),
        }).then((result) => this.checkStatus(result));
    }

    public async addStation(stationData: any): Promise<ApiResult<Station>> {
        const formattedData = Station.toApiObject(stationData);
        formattedData.stationId = stationData.stationId;
        return this.service.create<Station>(this.getPath('/station'), formattedData, {
            decoder: (data: any): Station => new Station(data.station),
        }).then((result) => this.checkStatus(result));
    }

    public async getStation(id: string, params?: any): Promise<ApiResult<Station>> {
        return this.service.read<Station>(this.getPath(`/station/${id}`), {
            ...params,
            decoder: (data: any): Station => new Station(data.station),
        }).then((result) => this.checkStatus(result));
    }

    public async updateStation(id: string, stationData: any): Promise<ApiResult<Station>> {
        const formattedData = Station.toApiObject(stationData);
        if (!this.service.session.isAdmin) {
            delete formattedData.userId;
            delete formattedData.qcReason;
            delete formattedData.qc;
            delete formattedData.confidence;
            delete formattedData.stationId;
            delete formattedData.placeName;
            delete formattedData.stateName;
            delete formattedData.pdwElev;
            delete formattedData.country;
        }

        if (this.service.session.isAdmin && stationData.qcReason) {
            formattedData.qcReason = stationData.qcReason;
        }

        return this.service.update<Station>(this.getPath(`/station/${id}`), formattedData, {
            decoder: (data: any): Station => new Station(data.station),
        }).then((result) => this.checkStatus(result));
    }

    public async resetKey(id: string): Promise<ApiResult<Station>> {
        return this.service.read<Station>(this.getPath(`/station/${id}/key`), {
            decoder: (data: any): Station => new Station(data.station),
        }).then((result) => this.checkStatus(result));
    }

    public async deleteStation(id: string): Promise<ApiResult<Station>> {
        return this.service.delete<Station>(this.getPath(`/station/${id}`))
            .then((result) => this.checkStatus(result));
    }

    public async getObservations(id: string, params?: any): Promise<ApiResult<Observations>> {
        return this.service.read<Observations>(this.getPath(`/station/${id}/observations`), {
            ...params,
            decoder: (data: any): Observations => new Observations(data.obs),
        }).then((result) => this.checkStatus(result));
    }

    public async downloadObservations(id: string, params: any = {}): Promise<ApiResult<FileDownload>> {
        return this.service.read<FileDownload>(this.getPath(`/station/${id}/observations`), {
            ...params,
            decoder: (data: any): FileDownload => new FileDownload(data.file),
        }).then((result) => this.checkStatus(result));
    }

    public async getLogs(id: string, params?: any): Promise<ApiResult<StationLogs>> {
        return this.service.read<StationLogs>(this.getPath(`/station/${id}/logs`), {
            ...params,
            decoder: (data: any): StationLogs => new StationLogs(data.logs),
        }).then((result) => this.checkStatus(result));
    }

}

export default StationsRoute;
