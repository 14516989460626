interface KeyValuePair<K, V> {
    key: K;
    value: V;
}

class LocalStorageChangeEvent<T> {

    static eventName = 'onLocalStorageChange';

    event: CustomEvent;

    constructor(payload: KeyValuePair<string, T>) {
        this.event = new CustomEvent(LocalStorageChangeEvent.eventName, { detail: payload });
        // super(LocalStorageChangeEvent.eventName, { detail: payload });
    }

}

export function isTypeOfLocalStorageChanged<T>(e: any): e is LocalStorageChangeEvent<T> {
    return (!!e) && (
        e instanceof LocalStorageChangeEvent
        || (e.detail && e.type === LocalStorageChangeEvent.eventName)
    );
}

export default LocalStorageChangeEvent;
