import Collection from '../Collection';
import StationLog from './StationLog';

class StationLogs extends Collection<StationLog> {

    constructor(data: any[] = []) {
        super(data, (model: any) => new StationLog(model));
    }

    sortDateAscending() {
        this.sort('date', false);
        return this;
    }

    sortDateDescending() {
        this.sort('date', true);
        return this;
    }

}

export { StationLog };
export default StationLogs;
