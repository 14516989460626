import DavisStations from '../../models/stations/DavisStations';
import ApiRoute from '../ApiRoute';

class DavisRoute extends ApiRoute {

    public get path(): string {
        return '/integration/davis';
    }

    public async getStations(params?: any): Promise<any> { // TODO return type
        return this.service.read(this.getPath('/stations'), {
            ...params,
            decoder: (data: any) => new DavisStations(data.stations) // TODO Model
            ,
        }).then((result) => this.checkStatus(result));
    }

    public checkStatus(result: ApiResult<any>): ApiResult<any> {
        return result;
    }

}

export default DavisRoute;
