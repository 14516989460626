import Model from '../Model';

interface DavisStation {
    stationId: string;
    name: string;
    city: string;
    region: string;
    country: string;
    productNumber: string;
    latitude: string;
    longitude: string;
    elevation: string;
    publicKey: string;
    privateKey: string;
    pwsId: string;
}

class DavisStation extends Model {

    protected get isMutable(): boolean {
        return true;
    }

    protected get mapping(): { [key: string]: string } {
        return {
            stationId: 'station_id',
            name: 'station_name',
            city: 'city',
            state: 'region',
            country: 'country',
            productNumber: 'product_number',
            latitude: 'latitude',
            longitude: 'longitude',
            elevation: 'elevation',
            pwsId: 'pwsId',
        };
    }

}

export default DavisStation;
