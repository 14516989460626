class PagingInfo {

    public total: number;

    public limit: number;

    public page: number;

    public totalPages: number;

    public hasPrevious: boolean;

    public hasNext: boolean;

    constructor({
        totalResults = 0,
        limit = 0,
        page = 1,
        totalPages = 1,
        hasPrevPage = false,
        hasNextPage = false,
    }: any = {}) {
        this.total = totalResults;
        this.limit = limit;
        this.page = page;
        this.totalPages = totalPages;
        this.hasPrevious = hasPrevPage;
        this.hasNext = hasNextPage;

        Object.freeze(this);
    }

}

export default PagingInfo;
