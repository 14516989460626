import PersistentStore from './PersistentStore';

type AuthToken = string;

class AuthTokenStore {

    public store: PersistentStore<AuthToken> = null;

    constructor() {
        this.store = new PersistentStore<AuthToken>();
    }

    public get key(): string {
        return 'authToken';
    }

    public getToken(): string {
        return this.store.read(this.key);
    }

    public setToken(token:string) {
        this.store.write(this.key, token);
    }

}

export const store = new AuthTokenStore();

// const useAuthToken = () => {
//     const [localState, setLocalState] = useState<AuthToken>(store.getToken());

//     const onLocalStorageChange = (e: any | StorageEvent) => {
//         let eventKey: string;
//         if (isTypeOfLocalStorageChanged(e)) {
//             eventKey = (<any>e).detail.key;
//         } else if (e.key === store.key) {
//             eventKey = e.newValue;
//         }

//         if (eventKey === store.key) {
//             setLocalState(store.getToken());
//         }
//     };

//     useEffect(() => {
//         const listener = (e: Event) =>
//             onLocalStorageChange(e as LocalStorageChangeEvent<AuthToken>);
//         window.addEventListener(LocalStorageChangeEvent.eventName, listener);
//         window.addEventListener("storage", listener);

//         return () => {
//             window.removeEventListener(LocalStorageChangeEvent.eventName, listener);
//             window.removeEventListener("storage", listener);
//         };
//     }, []);

//     return {
//         authToken: localState,
//         getToken: store.getToken,
//         setToken: store.setToken,
//     };
// };

// export default useAuthToken;
