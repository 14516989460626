import { isArray, isset } from '@aerisweather/javascript-sdk/dist/utils';
import Model from './Model';

abstract class Collection<T> {

    private storedModels: T[] = [];

    constructor(data: any[] = [], decoder?: (data: any) => T) {
        this.storedModels = [];

        if (decoder && isArray(data)) {
            this.storedModels = data.map((modelData: any) => decoder(modelData));
        }
    }

    public get models(): T[] {
        return this.storedModels;
    }

    public get count(): number {
        return this.models.length;
    }

    public append(models: T[]) {
        this.storedModels = this.models.concat(models);
    }

    public empty() {
        this.storedModels = [];
    }

    public sort(property: string, desc: boolean = false) {
        this.storedModels = this.storedModels.sort((obj1: any, obj2: any): number => {
            const v1 = obj1[property];
            const v2 = obj2[property];
            if (desc) {
                return v2 - v1;
            }
            return v1 - v2;
        });
    }

    public at(index: number): T {
        if (index >= this.models.length) {
            return null;
        }
        return this.models[index];
    }

    public find(prop: string, value: any): T {
        let result: T;

        this.models.forEach((model: any) => {
            if (!result && model.getValue(prop) === value) {
                result = model;
            }
        });

        return result;
    }

    public toArray(props: string[] = null): any[] {
        return this.models.map((model) => {
            if (model instanceof Model) {
                return model.toObject(props);
            }
            return null;
        }).filter((result) => isset(result));
    }

}

export default Collection;
