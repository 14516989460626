import React, { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider, DarkTheme, CSSReset, GlobalStyle } from 'pws-design-system/design-system';
import Header from './Header';
import { AppContextProvider } from '../../context/auth';

interface LayoutProps {
    children: ReactNode | Array<ReactNode>;
}

const Layout = ({ children }: LayoutProps) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <AppContextProvider>
            <ThemeProvider theme={DarkTheme}>
                <CSSReset />
                <GlobalStyle />
                <Header siteTitle={data.site.siteMetadata.title} />
                <main>
                    {children}
                </main>
            </ThemeProvider>
        </AppContextProvider>
    );
};

export default Layout;
