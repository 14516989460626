import { useState, useEffect } from 'react';
import PersistentStore from '../../../store/PersistentStore';
import LocalStorageChangeEvent, {
    isTypeOfLocalStorageChanged,
} from '../../../store/LocalStorageChangeEvent';
import { MeasureType, UnitType } from '../../../types/enums';

interface UnitSettings {
    [MeasureType.Temp]: UnitType;
    [MeasureType.Speed]: UnitType;
    [MeasureType.Distance]: UnitType;
    [MeasureType.Height]: UnitType;
    [MeasureType.Pressure]: UnitType;
    [MeasureType.Precip]: UnitType;
    [MeasureType.Rain]: UnitType;
    [MeasureType.Snow]: UnitType;
    [MeasureType.Percent]: UnitType;
}

const UNIT_DEFAULTS: UnitSettings = {
    [MeasureType.Temp]: UnitType.Imperial,
    [MeasureType.Speed]: UnitType.Imperial,
    [MeasureType.Distance]: UnitType.Imperial,
    [MeasureType.Height]: UnitType.Imperial,
    [MeasureType.Pressure]: UnitType.Imperial,
    [MeasureType.Precip]: UnitType.Imperial,
    [MeasureType.Rain]: UnitType.Imperial,
    [MeasureType.Snow]: UnitType.Imperial,
    [MeasureType.Percent]: UnitType.Imperial,
};

class UnitStore {

    public store: PersistentStore<UnitSettings>;

    constructor() {
        this.store = new PersistentStore<UnitSettings>();
    }

    public get key(): string {
        return 'units';
    }

    public getAll(): UnitSettings {
        return this.store.read(this.key, UNIT_DEFAULTS);
    }

    public get(type: MeasureType): UnitType {
        const payload = this.getAll();
        return <UnitType>payload[type];
    }

    public set(type: any, unit: UnitType) {
        const payload = this.store.read(this.key);
        this.store.write(this.key, { ...payload, [type]: unit });
    }

}

export const store = new UnitStore();

const useUnitStore = () => {
    const [localState, setLocalState] = useState<UnitSettings>(store.getAll());

    const onLocalStorageChange = (e: any | StorageEvent) => {
        let eventKey: string;
        if (isTypeOfLocalStorageChanged(e)) {
            eventKey = (<any>e).detail.key;
        } else if (e.key === store.key) {
            eventKey = e.newValue;
        }

        if (eventKey === store.key) {
            setLocalState(store.getAll());
        }
    };

    useEffect(() => {
        const listener = (e: Event) => (
            onLocalStorageChange(e as LocalStorageChangeEvent<UnitSettings>)
        );
        window.addEventListener(LocalStorageChangeEvent.eventName, listener);
        window.addEventListener('storage', listener);

        return () => {
            window.removeEventListener(LocalStorageChangeEvent.eventName, listener);
            window.removeEventListener('storage', listener);
        };
    }, []);

    return {
        units: localState,
        getUnit: store.get,
        setUnit: store.set,
    };
};

export default useUnitStore;
