import axios, { AxiosInstance } from 'axios';
import ApiResult from './ApiResult';
import ApiError from './ApiError';
import AuthRoute from './routes/AuthRoute';
import UsersRoute from './routes/UsersRoute';
import StationsRoute from './routes/StationsRoute';
import AccountRoute from './routes/AccountRoute';
import LocationRoute from './routes/LocationRoute';
import DownloadRoute from './routes/DownloadRoute';
import DavisRoute from './routes/DavisRoute';
import User from '../models/users/User';
import { AppState } from '../context/auth';
import { store as authTokenStore } from '../store/AuthToken';

interface ApiSession {
    user: User;
    isAdmin: boolean;
    logout: () => void;
}

const PWS_API_SERVER = process.env.GATSBY_PWS_API_SERVER; // eslint-disable-line

class Api {

    public service: AxiosInstance;

    public routes: {
        auth: AuthRoute,
        account: AccountRoute,
        users: UsersRoute,
        stations: StationsRoute,
        location: LocationRoute,
        download: DownloadRoute,
        davis: DavisRoute
    };

    public session: AppState;

    constructor() {
        this.service = axios.create({
            baseURL: PWS_API_SERVER,
        });

        this.service.defaults.withCredentials = true;

        this.routes = {
            auth: new AuthRoute(this),
            account: new AccountRoute(this),
            users: new UsersRoute(this),
            stations: new StationsRoute(this),
            location: new LocationRoute(this),
            download: new DownloadRoute(this),
            davis: new DavisRoute(this),
        };

        Object.freeze(this.service);
        Object.freeze(this.routes);
    }

    set authToken(token:string) {
        authTokenStore.setToken(token);
    }

    get authToken() {
        return authTokenStore.getToken();
    }

    setServiceAuthTokenHeader() {
        if (this.authToken) {
            this.service.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
            return;
        }
        delete this.service.defaults.headers.common.Authorization;
    }

    public async create<T>(path: string, data: any, opts: any = {}): Promise<ApiResult<T>> {
        const { decoder, ...params } = opts;
        this.setServiceAuthTokenHeader();
        const response = await this.service.post(path, data, { params });
        return new ApiResult<T>(response, decoder, params);
    }

    public async read<T>(path: string, opts: any = {}): Promise<ApiResult<T>> {
        const { decoder, ...params } = opts;
        this.setServiceAuthTokenHeader();
        const response = await this.service.get(path, { params }).catch((err) => err.response);
        return new ApiResult<T>(response, decoder, params);
    }

    public async update<T>(path: string, data: any, opts: any = {}): Promise<ApiResult<T>> {
        const { decoder, ...params } = opts;
        this.setServiceAuthTokenHeader();
        const response = await this.service.put(path, data, { params });
        return new ApiResult<T>(response, decoder, params);
    }

    public async post<T>(path: string, data: any, opts: any = {}): Promise<ApiResult<T>> {
        const { decoder, ...params } = opts;
        this.setServiceAuthTokenHeader();
        const response = await this.service.post(path, data, { params }).catch((err) => err.response);
        return new ApiResult<T>(response || {}, decoder, params);
    }

    public async delete<T>(path: string, data: any = {}, opts: any = {}): Promise<ApiResult<T>> {
        const { decoder, ...params } = opts;
        this.setServiceAuthTokenHeader();

        const response = await this.service.delete(path, { data: { ...data } }, { params });
        return new ApiResult<T>(response, decoder, params);
    }

}

export { ApiResult, ApiError };

export default Api;
