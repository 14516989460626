class ApiError {

    public code: string;

    public message: string;

    public info: any;

    constructor(info: any) {
        const { code, description } = info || {};
        this.code = code;
        this.info = description;

        const messages: string[] = [];
        if (description) {
            if (typeof description === 'string') {
                messages.push(description);
            } else {
                const { body } = description;
                if (Array.isArray(body)) {
                    body.forEach((error: any) => messages.push(error.message));
                }
            }
        }

        this.message = messages.join('\n');

        Object.freeze(this);
    }

}

export default ApiError;
