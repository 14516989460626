import Model from './Model';

interface Place {
    latitude: number;
    longitude: number;
    elevationM: number;
    elevationFT: number;
}

class Place extends Model {

    protected get mapping(): { [key: string]: string } {
        return {
            latitude: 'loc.lat',
            longitude: 'loc.long',
            elevationM: 'profile.elevM',
            elevationFT: 'profile.elevFT',
        };
    }

}

export default Place;
