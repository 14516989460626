import Model from '../Model';

class StationLog extends Model {

    protected get isMutable(): boolean {
        return false;
    }

    protected get mapping(): { [key: string]: string } {
        return {
            dateTimeISO: 'date',
            action: 'action',
            message: 'message',
            updatedBy: 'updatedBy',
            qc: 'qc',
            confidence: 'confidence',
        };
    }

    public get date() {
        return new Date(this.dateTimeISO);
    }

}

export default StationLog;
