import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import { dataTypeForProp } from '@aerisweather/javascript-sdk/dist/utils/units';
import { MeasureType, Units, UnitType } from '../types/enums';

export const isMetricValue = (value: string): boolean => value === 'metric';

export const unitsForMeasureType = (type: string, isMetric: boolean = false): string => {
    let units = '';
    switch (type) {
    case MeasureType.Temp:
        units = isMetric ? Units.Celsius : Units.Fahrenheit;
        break;
    case MeasureType.Speed:
        units = isMetric ? Units.KilometersPerHour : Units.MilesPerHour;
        break;
    case MeasureType.Height:
        units = isMetric ? Units.Meters : Units.Feet;
        break;
    case MeasureType.Distance:
        units = isMetric ? Units.Kilometers : Units.Miles;
        break;
    case MeasureType.Pressure:
        units = isMetric ? Units.Millibars : Units.Inches;
        break;
    case MeasureType.Precip:
    case MeasureType.Rain:
        units = isMetric ? Units.Millimeters : Units.Inches;
        break;
    case MeasureType.Snow:
        units = isMetric ? Units.Centimeters : Units.Inches;
        break;
    default:
    }

    return units;
};

export const fullUnitsForMeasureType = (type: string, isMetric: boolean = false): string => {
    let units = unitsForMeasureType(type, isMetric);

    switch (units) {
    case Units.Meters:
        units = 'meters';
        break;
    case Units.Feet:
        units = 'feet';
        break;
    default:
    }

    return units;
};

export const formatMeasurement = ( // eslint-disable-line import/prefer-default-export
    value: any,
    prop: MeasureType,
    isMetric: boolean = false,
    includeUnits: boolean = false,
    defaultValue: string = '--',
    unitsTemplate: string = null,
): string => {
    if (isset(value) === false) {
        return defaultValue;
    }

    let result = value;
    const type = dataTypeForProp(prop);
    if (!type) {
        return `${result}`;
    }

    let decimals = 0;
    let units: string = '';
    let spacer: string = ' ';

    if (type === MeasureType.Temp) {
        decimals = 1;
        spacer = '';
    } else if (type === MeasureType.Pressure) {
        decimals = isMetric ? 0 : 2;
    } else if (type === MeasureType.Rain) {
        decimals = isMetric ? 1 : 2;
    } else if (type === MeasureType.Snow) {
        decimals = 1;
    }

    if (decimals > 0) {
        result = parseFloat(result).toFixed(decimals);
    } else {
        result = Math.round(result);
    }

    if (includeUnits) {
        units = unitsForMeasureType(type, isMetric);
        if (isset(unitsTemplate)) {
            units = unitsTemplate.replace(/{{units}}/, units);
        }
    }

    return `${result}${spacer}${units}`;
};
