import ApiRoute from '../ApiRoute';
import ApiResult from '../ApiResult';
import { User } from '../../models/users/Users';

class AuthRoute extends ApiRoute {

    public get path(): string {
        return '/auth';
    }

    public async login(email: string, password: string): Promise<ApiResult<User>> {
        this.service.session.logout();
        return this.service
            .post<User>(
                this.getPath('/login'),
                {
                    email,
                    password,
                },
                {
                    decoder: (data: any) => {
                        this.service.authToken = data.token;
                        return new User(data.user);
                    },
                },
            )
            .then((result) => result);
    }

    public async resetPassword(email: string): Promise<ApiResult<User>> {
        return this.service.post<any>(this.getPath('/forgot-password'), {
            email,
        });
    }

    public async updateEmail(email:string): Promise<ApiResult<User>> {
       return this.service
            .post<User>(
                this.getPath('/update-email'),
                {
                    email,
                },
                {
                    decoder: (data: any) => {
                        this.service.authToken = data.token;
                        return new User(data.user);
                    },
                },
            )
            .then((result) => result);
    }

    public async logout(): Promise<ApiResult<any>> {
        this.service.session.logout();
        return this.service.read<any>(this.getPath('/logout')).then((result) => {
            this.service.authToken = null;
            return result;
        });
    }

}

export default AuthRoute;
