import Collection from '../Collection';
import DavisStation from './DavisStation';

class DavisStations extends Collection<DavisStation> {

    constructor(data: any[] = []) {
        super(data, (model: any) => new DavisStation(model));
    }

    public get associatedStations() {
        return this.models.filter((model) => model.pwsId);
    }

    public hasAssociatedStations() {
        return this.associatedStations.length;
    }

    public hasNoUnassociatedStations() {
        return this.associatedStations.length === this.models.length;
    }

}

export { DavisStation };
export default DavisStations;
