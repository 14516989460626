import ApiRoute from '../ApiRoute';
import ApiResult from '../ApiResult';
import { User } from '../../models/users/Users';

class AccountRoute extends ApiRoute {

    public get path(): string {
        return '/user';
    }

    public async getProfile(): Promise<ApiResult<User>> {
        return this.service.read<User>(this.getPath('/profile'), {
            decoder: (data: any): User => new User(data.user),
        })
            .then((result) => this.checkStatus(result))
            .then((result) => {
                if (result.success) {
                    this.service.session.user = result.object;
                    this.service.session.isAdmin = result.object.isAdmin;
                }
                return result;
            });
    }

    public async updateEmail(data: { email: string }): Promise<ApiResult<User>>  {
        return this.service.create<User>('/auth/update-email-request', data, {
            decoder: (data: any): User => new User(data.user),
        }).then((result) => this.checkStatus(result));
    }

    public async updateProfile(userData?: any): Promise<ApiResult<User>> {
        const formattedData = User.toApiObject(userData);

        if (userData.password === '') {
            delete formattedData.password;
        }

        if (userData.password2) {
            formattedData.password2 = userData.password2;
        }

        delete formattedData.email;

        delete formattedData.active;

        return this.service.update<User>(this.getPath('/profile'), formattedData, {
            decoder: (data: any): User => new User(data.user),
        }).then((result) => this.checkStatus(result));
    }

    public async deleteAccount(data: AnimationPlaybackEvent): Promise<ApiResult<User>> {
        return this.service
            .delete<User>(this.getPath("/profile"), data)
            .then((result) => this.checkStatus(result))
            .then((result) => {
                if (result.success) {
                    this.service.session.logout();
                    window.location.reload();
                }
                return result;
            });
    }

}

export default AccountRoute;
