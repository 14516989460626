import MeasurementModel from '../MeasurementModel';
import { formatMeasurement } from '../../utils/units';
import { MeasureType } from '../../types/enums';
import { BadgeStyle } from '../../types';

export const getQCStyle = (value: number): {
    color: string,
    style: BadgeStyle;
    label: string;
    labelColor: string;
} => {
    const style = 'solid';
    let color = 'green';
    let label = 'Good';
    const labelColor = 'gray.800';

    if (value <= 3) {
        label = 'Bad';
        color = 'red';
    } else if (value <= 7) {
        label = 'Questioned';
        color = 'yellow';
    }

    return {
        color, style, label, labelColor,
    };
};

interface Observation {
    id: string;
    dateTime: string;
    timestamp: number;
    qcCode: number;
    place: any;
    lat: number;
    lon: number;
    tempC: number;
    tempF: number;
    feelsLikeC: number;
    feelsLikeF: number;
    dewpointC: number;
    dewpointF: number;
    humidity: number;
    pressureMN: number;
    pressureIN: number;
    precipMM: number;
    precipIN: number;
    windSpeedKPH: number;
    windSpeedMPH: number;
    windGustKPH: number;
    windGustMPH: number;
    windDirection: string;
    windDirectionDegrees: number;
    weather: string;
    icon: string;
    skyCover: number;
    solarRadiation: number;
    uvIndex: number;
}

class Observation extends MeasurementModel {

    protected get mapping(): { [key: string]: string } {
        return {
            id: 'id',
            dateTime: 'ob.dateTimeISO',
            timestamp: 'ob.timestamp',
            qcCode: 'ob.QCcode',
            place: 'place',
            lat: 'loc.lat',
            lon: 'loc.long',
            tempC: 'ob.tempC',
            tempF: 'ob.tempF',
            feelsLikeC: 'ob.feelslikeC',
            feelsLikeF: 'ob.feelslikeF',
            dewpointC: 'ob.dewpointC',
            dewpointF: 'ob.dewpointF',
            humidity: 'ob.humidity',
            pressureMB: 'ob.pressureMB',
            pressureIN: 'ob.pressureIN',
            precipMM: 'ob.pressureMM',
            precipIN: 'ob.precipIN',
            windSpeedKPH: 'ob.windSpeedKPH',
            windSpeedMPH: 'ob.windSpeedMPH',
            windGustKPH: 'ob.windGustKPH',
            windGustMPH: 'ob.windGustMPH',
            windDirection: 'ob.windDir',
            windDirectionDegrees: 'ob.windDirDEG',
            weather: 'ob.weatherPrimary',
            icon: 'ob.icon',
            skyCover: 'ob.sky',
            solarRadiation: 'ob.solradWM2',
            uvIndex: 'ob.uvi',
        };
    }

    public get coordinate(): { lat: number, lon: number } {
        return { lat: this.lat, lon: this.lon };
    }

    public get temp(): number {
        return this.isMetric(MeasureType.Temp) ? this.tempC : this.tempF;
    }

    public get tempFormatted(): string {
        const measurement = MeasureType.Temp;
        return formatMeasurement(this.temp, measurement, this.isMetric(measurement), true);
    }

    public get feelsLike(): number {
        return this.isMetric(MeasureType.Temp) ? this.feelsLikeC : this.feelsLikeF;
    }

    public get feelsLikeFormatted(): string {
        const measurement = MeasureType.Temp;
        return formatMeasurement(this.feelsLike, measurement, this.isMetric(measurement), true);
    }

    public get dewpoint(): number {
        return this.isMetric(MeasureType.Temp) ? this.dewpointC : this.dewpointF;
    }

    public get dewpointFormatted(): string {
        const measurement = MeasureType.Temp;
        return formatMeasurement(this.dewpoint, measurement, this.isMetric(measurement), true);
    }

    public get humidityFormatted(): string {
        return `${this.humidity}%`;
    }

    public get pressure(): number {
        return this.isMetric(MeasureType.Pressure) ? this.pressureMB : this.pressureIN;
    }

    public get pressureFormatted(): string {
        const measurement = MeasureType.Pressure;
        return formatMeasurement(this.pressure, measurement, this.isMetric(measurement), true);
    }

    public get precip(): number {
        return this.isMetric(MeasureType.Precip) ? this.precipMM : this.precipIN;
    }

    public get precipFormatted(): string {
        const measurement = MeasureType.Precip;
        return formatMeasurement(this.precip, measurement, this.isMetric(measurement), true);
    }

    public get windSpeed(): number {
        return this.isMetric(MeasureType.Speed) ? this.windSpeedKPH : this.windSpeedMPH;
    }

    public get windSpeedFormatted(): string {
        const measurement = MeasureType.Speed;
        return formatMeasurement(this.windSpeed, measurement, this.isMetric(measurement), true);
    }

    public get windGust(): number {
        return this.isMetric(MeasureType.Speed) ? this.windGustKPH : this.windGustMPH;
    }

    public get windGustFormatted(): string {
        const measurement = MeasureType.Precip;
        return formatMeasurement(this.windGust, measurement, this.isMetric(measurement), true);
    }

    public get utcOffset(): string {
        if (!this.dateTime) { return null; }
        const { length } = this.dateTime;
        const offset = this.dateTime.slice(length - 6, length);
        return offset;
    }

}

export default Observation;
