import LocalStorageChangeEvent from './LocalStorageChangeEvent';

const isBrowser = (): boolean => typeof window !== 'undefined';

const tryParse = (value: string): any => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

class PersistentStore<T> {

    public read(key: string, defaultValue: T = null): T {
        if (!isBrowser()) { return defaultValue; }
        return localStorage.getItem(key) === null
            ? defaultValue
            : tryParse(localStorage.getItem(key));
    }

    public write(key: string, value: T) {
        if (this.validate(value) === false) {
            console.warn(`Cannot set invalid value ${value} for persistent store`); // eslint-disable-line no-console
            return;
        }
        try {
            localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : `${value}`);
            if (isBrowser()) {
                const { event } = new LocalStorageChangeEvent({ key, value });
                window.dispatchEvent(<any>event);
            }
        } catch (err) {
            if (err instanceof TypeError && err.message.includes('circular structure')) {
                //
            }
            throw err;
        }
    }

    public delete(key: string) {
        localStorage.removeItem(key);
        if (isBrowser()) {
            const { event } = new LocalStorageChangeEvent({ key, value: '' });
            window.dispatchEvent(<any>event);
        }
    }

    public validate(): boolean {
        return true;
    }

}

export default PersistentStore;
