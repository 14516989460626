import { AxiosResponse } from 'axios';
import ApiError from './ApiError';
import PagingInfo from './PagingInfo';

class ApiResult<T> {

    public success: boolean = false;

    public status: number;

    public data: any;

    public object: T;

    public error: ApiError;

    public paging: PagingInfo;

    public response: AxiosResponse;

    public params: any;

    constructor(response: AxiosResponse, decoder: (data: any) => T = null, params: any) {
        const { data, status } = response || {};
        const { success, error, response: responseData } = data || { success: false };

        this.response = response;
        this.success = success;
        this.error = error ? new ApiError(error) : null;
        this.data = responseData;
        this.object = (decoder && responseData) ? decoder(responseData) : null;
        this.status = status;
        this.params = params;

        if (responseData && responseData.meta) {
            this.paging = new PagingInfo(responseData.meta);
        } else {
            this.paging = new PagingInfo();
        }

        Object.freeze(this);
    }

}

export default ApiResult;
