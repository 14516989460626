import { get, set, has, isset, isEmpty } from '@aerisweather/javascript-sdk/dist/utils';

export const getAllMethods = (obj: any) => {
    let props: string[] = [];

    do {
        const l = Object.getOwnPropertyNames(obj)
            .concat(Object.getOwnPropertySymbols(obj).map((s) => s.toString()))
            .sort()
            .filter((p, i, arr) => typeof obj[p] === 'function' // only the methods
                && p !== 'constructor' // not the constructor
                && (i == 0 || p !== arr[i - 1]) // not overriding in this prototype
                && props.indexOf(p) === -1, // not overridden in a child
            );
        props = props.concat(l);
    }
    while (
        (obj = Object.getPrototypeOf(obj)) // walk-up the prototype chain
        && Object.getPrototypeOf(obj) // not the the Object prototype methods (hasOwnProperty, etc...)
    );

    return props;
};

const hasGetter = (obj: any, prop: string): boolean => {
    const descriptor = Object.getOwnPropertyDescriptor(obj.constructor.prototype, prop);
    return isset(descriptor) && isset(descriptor.get);
};

abstract class Model {

    public data: any = null;

    protected get isMutable(): boolean {
        return false;
    }

    protected get mapping(): { [key: string]: string } {
        return {};
    }

    // properties that can get sent with post/put requests to the api
    protected get editableProps(): string[] {
        return [];
    }

    constructor(data?: any) {
        this.data = { ...data };

        // setup mapped getters if not already defined in class
        Object.keys(this.mapping).forEach((prop) => {
            const mappedProp = this.mapping[prop];

            // fill in missing data fields from mapping
            if (!isset(this.getValue(mappedProp))) {
                this.setValue(mappedProp, null);
            }

            if (has(this, prop) === false && hasGetter(this, prop) === false) {
                if (this.isMutable) {
                    Object.defineProperty(this, prop, {
                        get: () => this.getValue(mappedProp),
                        set: (value: any) => {
                            this.setValue(mappedProp, value);
                        },
                        enumerable: true,
                        configurable: true,
                    });
                } else {
                    Object.defineProperty(this, prop, {
                        get: () => this.getValue(mappedProp),
                    });
                }
            }
        });

        if (this.isMutable === false) {
            // seal `data` so no random properties can be set on model data is immutable
            Object.seal(this.data);
        }
    }

    public get hasData(): boolean {
        return !isEmpty(this.data);
    }

    public has(path: string): boolean {
        return isset(this.get(path));
    }

    public get(path: string): any {
        return get(this.data, path);
    }

    public set(path: string, value: any) {
        set(this.data, path, value);
    }

    public getValue(prop: string): any {
        if (isset(this.mapping[prop])) {
            return this.get(this.mapping[prop]);
        }
        return this.get(prop);
    }

    public setValue(prop: string, value: any) {
        if (isset(this.mapping[prop])) {
            return this.set(this.mapping[prop], value);
        }
        return this.set(prop, value);
    }

    public getData(): any {
        return { ...this.data };
    }

    public toObject(props: string[] = null): any[] {
        let propsToGet = props;

        // no props provided, so return all valid properties for the model instance
        if (!isset(propsToGet)) {
            const descriptors = this.getDescriptors();
            if (descriptors) {
                const skipKeys = ['constructor', 'mapping', 'data', 'isMutable'];
                propsToGet = Object.keys(descriptors)
                    .filter((key) => (skipKeys.indexOf(key) === -1 && /^__/.test(key) === false));
            }
        }

        return (propsToGet || []).reduce((result: any, prop) => {
            let propResult: any;
            const descriptor = this.getPropDescriptor(prop);
            if (descriptor && descriptor.get) {
                propResult = descriptor.get.bind(this).call();
            } else {
                propResult = this.getValue(prop);
            }

            if (propResult instanceof Model) {
                propResult = { ...(<Model>propResult).toObject() };
            }

            result[prop] = propResult; // eslint-disable-line no-param-reassign

            return result;
        }, {});
    }

    public clone(): any {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    private getDescriptors(): any {
        const prototypeDescriptors = Object.getOwnPropertyDescriptors(this.constructor.prototype);
        const instanceDescriptors = Object.getOwnPropertyDescriptors(this);
        return { ...prototypeDescriptors, ...instanceDescriptors };
    }

    private getPropDescriptor(prop: string): any {
        let descriptor = Object.getOwnPropertyDescriptor(this, prop);
        if (!descriptor) {
            descriptor = Object.getOwnPropertyDescriptor(this.constructor.prototype, prop);
        }

        return descriptor;
    }

    public static toApiObject(data: any): any {
        const result: any = {};
        const allowed = this.prototype.editableProps;

        // map data properties to their respective property the api expects
        Object.keys(data).forEach((key) => {
            const k = this.prototype.mapping[key];
            if (k && allowed.indexOf(k) !== -1) {
                result[k] = data[key];
            }
        });

        return result;
    }

}

export default Model;
