import Collection from '../Collection';
import Observation from './Observation';

class Observations extends Collection<Observation> {

    constructor(data: any[] = []) {
        super(data, (model: any) => new Observation(model));
    }

}

export { Observation };
export default Observations;
