import React, { createContext, useContext, useReducer, ReactElement, useEffect, useLayoutEffect } from 'react';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import User from '../models/users/User';
import useApi, { Api } from '../components/hooks/api/useApi';
import { store as unitStore } from '../components/hooks/store/useUnitStore';

enum AuthState {
    Authenticate = 'authenticate',
    Login = 'login',
    Logout = 'logout',
    Update = 'update',
    Failed = 'failed'
}

export interface AppState {
    user: User;
    authToken: string;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    failedAuthentication: boolean;
    isAdmin: boolean;
    updateUser: (user: User) => void;
    logout: () => void;
}

export const initialState: AppState = {
    user: null,
    authToken: null,
    isAuthenticated: false,
    isAuthenticating: false,
    failedAuthentication: false,
    isAdmin: false,
    updateUser: () => {},
    logout: () => {},
};

export const AppContext = createContext(initialState);

const reducer = (state: any, action: any) => {
    const { user } = action.payload || {};
    switch (action.type) {
    case AuthState.Authenticate:
        return {
            ...state,
            isAuthenticating: true,
        };
    case AuthState.Login:
        return {
            ...state,
            isAuthenticating: false,
            isAuthenticated: isset(action.payload.user),
            failedAuthentication: false,
            user,
            isAdmin: user ? user.isAdmin : false,
            updateUser: action.payload.updateUser,
            logout: action.payload.logout,
        };
    case AuthState.Logout:
        return {
            ...state,
            isAuthenticated: false,
            failedAuthentication: false,
            user: null,
            isAdmin: false,
            updateUser: () => {},
            logout: () => {},
        };
    case AuthState.Update:
        return {
            ...state,
            user,
        };
    default:
        return {
            ...initialState,
            isAuthenticating: false,
            failedAuthentication: true,
        };
    }
};

let cachedUser: User = null;
let lastAuthTime: Date = null;

export const AppContextProvider = (props: any): ReactElement => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, user: cachedUser });
    const { request: fetchUser, result } = useApi<User>((api: Api) => {
        const urlParams = new URLSearchParams(window.location.search) as URLSearchParamsExtended;
        if (urlParams.has('authToken')) {
            const authToken = urlParams.get('authToken');
            api.authToken = authToken;
            urlParams.delete('authToken');
            window.history.replaceState(
                {},
                '',
                `${location.pathname}${Array.from(urlParams.keys()).length ? `?${urlParams}` : ''}`,
            );
        }
        return api.routes.account.getProfile();
    });

    const logout = () => {
        cachedUser = null;
        dispatch({
            type: AuthState.Logout,
        });
    };

    useEffect(() => {
        console.log('checking auth', state.user, lastAuthTime);
        const isExpired = lastAuthTime && Date.now() - lastAuthTime.getTime() >= 300 * 1000;
        if (!isset(state.user) || isExpired === true) {
            cachedUser = null;
            dispatch({
                type: AuthState.Authenticate,
            });
            fetchUser();
        } else {
            dispatch({
                type: AuthState.Login,
                payload: {
                    user: cachedUser,
                    updateUser: (user: User) => {
                        dispatch({
                            type: AuthState.Update,
                            payload: {
                                user,
                            },
                        });
                    },
                    logout,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (result.success) {
            cachedUser = result.object;
            lastAuthTime = new Date();
            dispatch({
                type: AuthState.Login,
                payload: {
                    user: cachedUser,
                    logout,
                },
            });
        } else if (result.response) {
            dispatch({
                type: AuthState.Failed,
            });
        }
    }, [result]);

    useEffect(() => {
        const { user } = state;
        if (user && user.preferences) {
            const prefs = { ...user.preferences.units, ...user.preferences.time };
            Object.keys(prefs).forEach((key) => unitStore.set(key, prefs[key]));
        }
    }, [state.user]);

    return (
        <AppContext.Provider
            value={{
                ...state,
            }}
            {...props}
        />
    );
};

const useAuth = () => useContext(AppContext);

export default useAuth;
