import Collection from '../Collection';
import Station from './Station';

class Stations extends Collection<Station> {

    constructor(data: any[] = []) {
        super(data, (model: any) => new Station(model));
    }

}

export { Station };
export default Stations;
