import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import Model from '../Model';
import { store as unitStore } from '../../components/hooks/store/useUnitStore';
import { MeasureType } from '../../types/enums';
import { isMetricValue } from '../../utils/units';

interface Location {
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    elevationFT: number;
}

class Location extends Model {

    protected get mapping(): { [key: string]: string } {
        return {
            latitude: 'lat',
            longitude: 'long',
            address: 'street',
            city: 'city',
            state: 'state',
            country: 'country',
            postalCode: 'zipcode',
            elevationFT: 'elev',
            precision: 'precision',
        };
    }

    // properties that can get sent with post/put requests to the api
    protected get editableProps(): string[] {
        return [
            'street',
            'city',
            'state',
            'country',
            'zipcode',
            'lat',
            'long',
            'elev',
            'precision',
        ];
    }

    protected get isMutable(): boolean {
        return true;
    }

    public get place(): string {
        const parts = [this.city, this.state, this.country]
            .filter((v) => isset(v));
        return parts.join(', ');
    }

    public get coordinate(): { lat: number, lon: number } {
        return { lat: this.latitude, lon: this.longitude };
    }

    public get precision(): string {
        return `${this.get('precision')}`;
    }

    public set precision(value: string) {
        this.set('precision', parseInt(value, 10));
    }

    public get elevation(): number {
        const elev = Math.round(isMetricValue(unitStore.get(MeasureType.Height)) ? this.elevationM : this.elevationFT);
        return elev;
    }

    public get elevationM(): number {
        return Math.round(this.get('elev') * 0.3048);
    }

}

export default Location;
