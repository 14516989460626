import Api from './Api';
import ApiResult from './ApiResult';

class ApiRoute {

    public service: Api;

    constructor(service: Api) {
        this.service = service;
        Object.freeze(this);
    }

    public get path(): string {
        return null;
    }

    public getPath(path: string = ''): string {
        if (this.path) {
            return `${this.path}${path}`;
        }
        return path;
    }

    public checkStatus(result: ApiResult<any>): ApiResult<any> {
        if (result.status) {
            const statusCode = result.status;
            // if returned status code is 401, then user session has expired or is invalid
            // so redirect them to the login page
            if (statusCode === 401) {
                this.service.session.logout();
            }
        }
        return result;
    }

}

export default ApiRoute;
