import Model from '../Model';
import Location from './Location';
import User from '../users/User';
import { BadgeStyle } from '../../types';
import { StatusType } from '../../types/enums';

export const getStatusDescription = (value: StatusType): string => {
    const descriptions = {
        [StatusType.Active]: 'The station has sent data within the past 30 days and is passing quality control checks.',
        [StatusType.Inactive]: 'The station is not actively sending data. Contact support for more information.',
        [StatusType.Rejected]: 'The station has been rejected for sending invalid and/or bad data. Contact support for more information.',
        [StatusType.Initiating]: 'The station has been recently added and is sending data but is still in the processing of passing initial quality control checks. This process can take up to 7-10 days for new stations.',
        [StatusType.Questioned]: 'The station has sent data that does not pass quality control checks. Contact support for more information.',
        [StatusType.Unknown]: 'An error has occured. Contact support for more information.',
    };

    return descriptions[value];
};

export const getStatusStyle = (value: StatusType): { color: string; style: BadgeStyle; } => {
    const style = 'outline';
    let color = 'green';

    switch (value) {
    case StatusType.Inactive:
    case StatusType.Unknown:
        color = 'gray';
        break;
    case (StatusType.Questioned || StatusType.Initiating):
    case StatusType.Initiating:
    case StatusType.Questioned:
        color = 'yellow';
        break;
    case StatusType.Rejected:
        color = 'red';
        break;
    default:
    }

    return { color, style };
};

export const getStatusText = (value: string): string => {
    if (value === 'questioned') {
        return 'bad data';
    }
    return value;
};

export const getConfidenceStyle = (value: number): { color: string; style: BadgeStyle; text: string; } => {
    const style = 'solid';
    let color = 'green';
    const text = 'gray.800';

    if (value <= 3) {
        color = 'red';
    } else if (value <= 7) {
        color = 'yellow';
    }

    return { color, style, text };
};

interface Station {
    active: boolean;
    stationId: string;
    displayName: string;
    websiteUrl: string;
    hardware: string;
    software: string;
    apiKey: string;
    stateName: string;
    placeName: string;
    country: string;
    pdwElev: number;
    pressureType: string;
    registeredAt: string;
    lastUpdatedAt: string;
    confidence: number;
}

class Station extends Model {

    private storedLocation: Location;

    private storedUser: User;

    constructor(data: any = {}) {
        super(data);

        this.location = new Location(data.location || {});
        this.user = new User(data.user || {});
    }

    protected get isMutable(): boolean {
        return true;
    }

    protected get mapping(): { [key: string]: string } {
        return {
            id: 'id',
            active: 'active',
            stationId: 'stationId',
            userId: 'userId',
            displayName: 'name',
            websiteUrl: 'url',
            hardware: 'hardware',
            software: 'software',
            apiKey: 'appKey',
            stateName: 'stateName',
            placeName: 'placeName',
            pdwElev: 'pdwElev',
            country: 'country',
            pressureType: 'pressureType',
            registeredAt: 'registeredDate',
            lastUpdatedAt: 'lastUpdateDate',
            confidence: 'confidence',
            qc: 'qc',
            status: 'status',
            integration: 'integration',
        };
    }

    // properties that can get sent with post/put requests to the api
    protected get editableProps(): string[] {
        return [
            'active',
            // 'name',
            // 'url',
            'hardware',
            'software',
            'placeName',
            'pressureType',
            'stateName',
            'country',
            'pdwElev',
            'qc',
            'stationId',
            'confidence',
            'userId',
            'integration',
            'integration.enabled',
        ];
    }

    public get id(): string {
        return (`${this.getValue('id')}` || '').replace(/^PWS_/, '');
    }

    public get displayId(): string {
        return (this.stationId || '').replace(/^PWS_/, '');
    }

    public get status(): StatusType {
        const status = this.getValue('status');
        if (status === 'inactive') {
            return StatusType.Inactive;
        }

        if (status === 'active') {
            return StatusType.Active;
        }

        if (status === 'initiating') {
            return StatusType.Initiating;
        }

        if (status === 'questioned') {
            return StatusType.Questioned;
        }

        if (status === 'rejected') {
            return StatusType.Rejected;
        }

        return StatusType.Unknown;
    }

    public get dashboardUrl(): string {
        return `https://www.pwsweather.com/station/pws/${this.displayId}`;
    }

    public get location(): Location {
        return this.storedLocation;
    }

    public set location(value: Location) {
        this.storedLocation = value;
    }

    public get user(): User {
        return this.storedUser;
    }

    public set user(value: User) {
        this.storedUser = value;
    }

    public static toApiObject(data: any): any {
        const result = super.toApiObject(data);
        result.location = Location.toApiObject(data.location);

        return result;
    }

}

export default Station;
