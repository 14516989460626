import { useState, useEffect } from 'react';
import { isEmpty as _isEmpty } from '@aerisweather/javascript-sdk/dist/utils';
import Api from '../../../api/Api';
import ApiResult from '../../../api/ApiResult';
import ApiError from '../../../api/ApiError';
import PagingInfo from '../../../api/PagingInfo';
import useAuth from '../../../context/auth';

type ApiRequest = (api: Api, ...args: any[]) => Promise<ApiResult<any>>;

const api = new Api();

function useApi<T>(request: ApiRequest, isImmediate: boolean = false) {
    const [result, setResult] = useState(new ApiResult<T>(null));
    const [error, setError] = useState(null);
    const [paging, setPaging] = useState(new PagingInfo({ limit: 25 }));
    const [count, setCount] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const session = useAuth();

    const fetchData = async (data: any = {}) => {
        setIsLoading(true);
        setCount(count + 1);
        try {
            const apiResult = await request(api, data);
            setIsInitialLoad(false);
            setResult(apiResult);
            setIsEmpty(_isEmpty(apiResult.data));
            if (apiResult.paging) {
                setPaging(apiResult.paging);
            }
            if (apiResult.error) {
                setError(apiResult.error.message);
            }
            setIsLoading(false);
        } catch (fetchError) {
            const errorResult = new ApiResult<T>(fetchError.response);
            setResult(errorResult);
            setError(fetchError);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isImmediate) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        api.session = session;
    }, [session.isAuthenticated]);

    return {
        request: fetchData,
        result,
        error,
        paging,
        requestCount: count,
        isLoading,
        isEmpty,
        isInitialLoad,
    };
}

export { Api, ApiResult, ApiError };

export default useApi;
