import Model from './Model';

interface FileDownload {
    token: string;
    readProgress: number;
    writeProgress: number;
    fileSize: number;
    bytesWritten: number;
    timeRemaining: number;
}

class FileDownload extends Model {

    protected get mapping(): { [key: string]: string } {
        return {
            token: 'token',
            readProgress: 'readProgress',
            writeProgress: 'writeProgress',
            fileSize: 'fileSize',
            bytesWritten: 'bytesWritten',
            timeRemaining: 'timeRemaining',
        };
    }

}

export default FileDownload;
