import ApiRoute from '../ApiRoute';
import Users, { User } from '../../models/users/Users';
import ApiResult from '../ApiResult';

class UsersRoute extends ApiRoute {

    public get path(): string {
        return '/manage';
    }

    public async getUsers(params?: any): Promise<ApiResult<Users>> {
        return this.service.read<Users>(this.getPath('/users'), {
            ...params,
            decoder: (data: any): Users => new Users(data.users),
        }).then((result) => this.checkStatus(result));
    }

    public async addUser(userData: any): Promise<ApiResult<User>> {
        const formattedData = User.toApiObject(userData);
        formattedData.password2 = userData.password2;
        return this.service.create<User>(this.getPath('/user'), formattedData, {
            decoder: (data: any): User => new User(data),
        }).then((result) => this.checkStatus(result));
    }

    public async getUser(id: string, params?: any): Promise<ApiResult<User>> {
        return this.service.read<User>(this.getPath(`/user/${id}`), {
            ...params,
            decoder: (data: any): User => new User(data),
        }).then((result) => this.checkStatus(result));
    }

    public async updateUser(id: string, userData: any): Promise<ApiResult<User>> {
        const formattedData = User.toApiObject(userData);

        if (userData.password === '') {
            delete formattedData.password;
        }

        formattedData.password2 = userData.password2;

        return this.service.update<User>(this.getPath(`/user/${id}`), formattedData, {
            decoder: (data: any): User => new User(data),
        }).then((result) => this.checkStatus(result));
    }

    public async deleteUser(id: string): Promise<ApiResult<User>> {
        return this.service.delete<User>(this.getPath(`/user/${id}`))
            .then((result) => this.checkStatus(result));
    }

}

export default UsersRoute;
