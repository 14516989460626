import ApiRoute from '../ApiRoute';
import { ApiResult } from '../Api';
import Stations, { Station } from '../../models/stations/Stations';
import Observations from '../../models/stations/Observations';
import FileDownload from '../../models/FileDownload';
import StationLogs from '../../models/stations/StationLogs';

class DownloadRoute extends ApiRoute {

    // public get path(): string {
    //     return this.service.session.isAdmin ? "/manage" : "/user";
    // }

    public async getDownload(token: string): Promise<ApiResult<Stations>> {
        return this.service
            .read<Stations>(`${this.getPath('/download')}/${token}`, {
                decoder: (data: any): Stations => new Stations(data.stations),
            })
            .then((result) => this.checkStatus(result));
    }

}

export default DownloadRoute;
