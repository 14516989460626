/* eslint-disable no-unused-vars */

export enum UserRole {
    User = 'user',
    Admin = 'admin'
}

export enum StatusType {
    Inactive = 'inactive',
    Active = 'active',
    Questioned = 'bad data',
    Initiating = 'initiating',
    Rejected = 'rejected',
    Unknown = 'unknown',
}

export enum UnitType {
    Metric = 'metric',
    Imperial = 'imperial',
    Custom = 'custom'
}

export enum MeasureType {
    Temp = 'temp',
    Speed = 'speed',
    Distance = 'distance',
    Height = 'height',
    Pressure = 'pressure',
    Precip = 'precip',
    Rain = 'rain',
    Snow = 'snow',
    Percent = 'percent'
}

export enum Units {
    Celsius = '°C',
    Fahrenheit = '°F',
    KilometersPerHour = 'km/h',
    MilesPerHour = 'mph',
    Kilometers = 'km',
    Miles = 'mi',
    Meters = 'm',
    Feet = 'ft',
    Inches = 'in',
    Millibars = 'mb',
    Percent = '%',
    Millimeters = 'mm',
    Centimeters = 'cm'
}
